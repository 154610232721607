import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { languages } from "./constant";
import { deleteLanguagesApiEndPoint, getLanguagesApiEndPoint, postLangaugesApiEndPoint, updateLanguagesApiEndPoint } from "./api";
import { postLanguage } from "./action";
import ToastHandle from '../../../helpers/ToastMessage';
function* getLanguageFunction() {
    try {
        yield put({
            type: languages.GET_LANGUAGES_LOADING,
            payload: {},
        });
        const response = yield call(getLanguagesApiEndPoint);
        if (response.data.status) {
            yield put({
                type: languages.GET_LANGUAGES_SUCCESS,
                payload: { ...response.data },
            });
        } else {
            yield put({
                type: languages.GET_LANGUAGES_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: languages.GET_LANGUAGES_ERROR,
            payload: error?.data,
        });
        yield put({
            type: languages.GET_LANGUAGES_RESET,
            payload: {},
        });
    }
}

function* addLanguageFunction({ payload }) {

    try {
        yield put({
            type: languages.POST_LANGUAGES_LOADING,
            payload: {},
        });
        const response = yield call(postLangaugesApiEndPoint, { payload });
        if (response.data.status) {
            yield put({
                type: languages.POST_LANGUAGES_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: languages.POST_LANGUAGES_RESET,
                payload: {},
            });
        } else {
            yield put({
                type: languages.POST_LANGUAGES_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        if (error?.data?.message == "Language already exists") {
            ToastHandle(error.data.message, 'danger')
        }
        yield put({
            type: languages.POST_LANGUAGES_ERROR,
            payload: error?.data,
        });
        yield put({
            type: languages.POST_LANGUAGES_RESET,
            payload: {},
        });
    }
}

function* updateLanguagesFunction({ payload }) {
    try {
        yield put({
            type: languages.UPDATE_LANGUAGES_LOADING,
            payload: {},
        });
        const response = yield call(updateLanguagesApiEndPoint, { payload });
        if (response.data.status) {
            yield put({
                type: languages.UPDATE_LANGUAGES_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: languages.UPDATE_LANGUAGES_RESET,
                payload: {},
            });
        } else {
            yield put({
                type: languages.UPDATE_LANGUAGES_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: languages.UPDATE_LANGUAGES_ERROR,
            payload: error?.data,
        });
    }
}

function* deleteLanguagesFunction(action) {
    try {
        yield put({
            type: languages.DELETE_LANGUAGES_LOADING,
            payload: {},
        });
        const response = yield call(deleteLanguagesApiEndPoint, action);
        if (response.data.status) {
            yield put({
                type: languages.DELETE_LANGUAGES_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: languages.DELETE_LANGUAGES_RESET,
                payload: {},
            });
        } else {
            yield put({
                type: languages.DELETE_LANGUAGES_ERROR,
                payload: { ...response.data },
            });
            yield put({
                type: languages.DELETE_LANGUAGES_RESET,
                payload: {},
            });
        }
    } catch (error) {
        yield put({
            type: languages.DELETE_LANGUAGES_ERROR,
            payload: error,
        });
        yield put({
            type: languages.DELETE_LANGUAGES_RESET,
            payload: {},
        });
    }
}

export function* acctionLanguagesDetail(): any {
    yield takeEvery(
        languages.GET_LANGUAGES,
        getLanguageFunction
    );
}
export function* acctionAddLanguagesDetail(): any {
    yield takeEvery(
        languages.POST_LANGUAGES,
        addLanguageFunction
    );
}

export function* acctionUpdateLanguagesDetail(): any {
    yield takeEvery(
        languages.UPDATE_LANGUAGES,
        updateLanguagesFunction
    );
}

export function* acctionDeleteLanguagesDetail(): any {
    yield takeEvery(
        languages.DELETE_LANGUAGES,
        deleteLanguagesFunction
    );
}

function* allLanguagesSaga(): any {
    yield all([
        fork(acctionLanguagesDetail),
        fork(acctionAddLanguagesDetail),
        fork(acctionUpdateLanguagesDetail),
        fork(acctionDeleteLanguagesDetail),
    ]);
}
export default allLanguagesSaga;
