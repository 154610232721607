import { client } from "./constant";

const initial_state = {
  data: [],
  message: "",
  loading: false,
};

const unassigned_clients_initial_state = {
  data: [],
  message: "",
  loading: false,
};
const UPDATE_PROFILE_INITIAL_STATE = {
  updateClientProfile: [],
  loading: false,
  message: "",
}

export const getClientReducer = (state = initial_state, action) => {
  switch (action.type) {
    case client.GET_CLIENT_LOADING:
      return {
        data: initial_state.data,
        loading: true,
      };
    case client.GET_CLIENT_SUCCESS:
      return {
        data: action?.payload,
        loading: false,
      };
    case client.GET_CLIENT_ERROR:
      return {
        data: [],
        loading: false,
        message: action?.payload,
      };
    default:
      return { ...state };
  }
};
export const getUnassignedClientReducer = (state = unassigned_clients_initial_state, action) => {
  switch (action.type) {
    case client.GET_UNASSIGNED_CLIENT_LOADING:
      return {
        data: initial_state.data,
        loading: true,
      };
    case client.GET_UNASSIGNED_CLIENT_SUCCESS:
      return {
        data: action?.payload,
        loading: false,
      };
    case client.GET_UNASSIGNED_CLIENT_ERROR:
      return {
        data: [],
        loading: false,
        message: action?.payload,
      };
    default:
      return { ...state };
  }
};
export const updateProfileReducer = (
  state = UPDATE_PROFILE_INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case client.UPDATE_PROFILE_LOADING:
      return {
        updateClientProfile: state.updateClientProfile,
        loading: true,
      };
    case client.UPDATE_PROFILE_SUCCESS:
      return {
        updateClientProfile: action?.payload,
        loading: false,
      };
    case client.UPDATE_PROFILE_ERROR:
      return {
        updateClientProfile: action?.payload,
        loading: false,
      }
    case client.UPDATE_PROFILE_RESET:
      return UPDATE_PROFILE_INITIAL_STATE
    default:
      return { ...state };

  }
};
