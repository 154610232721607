import * as URL from "../../../helpers/api/apiEndPoint";
import { APICore } from "../../../helpers/api/apiCore";
const api = new APICore();

export function addChargeApi(data): any {
    return api.create(`${URL.ADD_CHARGE}`, data?.payload);
}
export function getChargesApi(data): any {
    return api.get(`${URL.GET_CHARGES}?flag=${data?.payload?.flag}&skip=${data?.payload?.skip}&pageSize=10`);
}
export function getSlabApi(data): any {
    return api.get(`${URL.GET_SLABS}`);
}

export function deleteChargeApi(data): any {
    return api.delete(`${URL.DELETE_CHARGE}?id=${data?.payload?.id}`);
}

export function updateChargeApi(data): any {
    return api.update(`${URL.UPDATE_CHARGE}`, data?.payload);
}