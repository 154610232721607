import { client } from "./constant";

export const getClientList = (data): AuthAction => ({
  type: client.GET_CLIENT,
  payload: data,
});

export const getUnassignedClientList = (data): AuthAction => ({
  type: client.GET_UNASSIGNED_CLIENT,
  payload: data,
});
export const updateClient = (data): AuthAction => ({
  type: client.UPDATE_PROFILE,
  payload: data,
});
