export const charge = {
    //   Create charge
    ADD_CHARGE: "ADD_CHARGE",
    ADD_CHARGE_LOADING: "ADD_CHARGE_LOADING",
    ADD_CHARGE_SUCCESS: "ADD_CHARGE_SUCCESS",
    ADD_CHARGE_ERROR: "ADD_CHARGE_ERROR",
    ADD_CHARGE_RESET: "ADD_CHARGE_RESET",

    GET_CHARGE: "GET_CHARGE",
    GET_CHARGE_LOADING: "GET_CHARGE_LOADING",
    GET_CHARGE_SUCCESS: "GET_CHARGE_SUCCESS",
    GET_CHARGE_ERROR: "GET_CHARGE_ERROR",
    GET_CHARGE_RESET: "GET_CHARGE_RESET",

    GET_SLAB: "GET_SLAB",
    GET_SLAB_LOADING: "GET_SLAB_LOADING",
    GET_SLAB_SUCCESS: "GET_SLAB_SUCCESS",
    GET_SLAB_ERROR: "GET_SLAB_ERROR",
    GET_SLAB_RESET: "GET_SLAB_RESET",

    DELETE_CHARGE: "DELETE_CHARGE",
    DELETE_CHARGE_LOADING: "DELETE_CHARGE_LOADING",
    DELETE_CHARGE_SUCCESS: "DELETE_CHARGE_SUCCESS",
    DELETE_CHARGE_ERROR: "DELETE_CHARGE_ERROR",
    DELETE_CHARGE_RESET: "DELETE_CHARGE_RESET",

    UPDATE_CHARGE: "UPDATE_CHARGE",
    UPDATE_CHARGE_LOADING: "UPDATE_CHARGE_LOADING",
    UPDATE_CHARGE_SUCCESS: "UPDATE_CHARGE_SUCCESS",
    UPDATE_CHARGE_ERROR: "UPDATE_CHARGE_ERROR",
    UPDATE_CHARGE_RESET: "UPDATE_CHARGE_RESET",
}