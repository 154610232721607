// @flow
import { combineReducers } from "redux";

import Auth from "./auth/reducers";
import Layout from "./layout/reducers";
// import { businessEmailReducer } from "./auth/businessEmail/reducers";
// import {
//   otpSendEmailReducer,
//   subAccountantSendEmailVerifyReducer,
// } from "./auth/otpSendEmail/reducers";
// import {
//   getCompanyNameReducer,
//   registerUserReducer,
//   companyCheckVerifyReducer,
//   companyCreateReducer,
// } from "./auth/verifyDetail/reducers";
// import {
//   resetPasswordEmailReducer,
//   resetPasswordVerifyReducer,
// } from "./auth/resetPassword/reducers";

// import {
//   organisationSettingReducer,
//   getOrganisationSettingReducer,
//   emailSendVerifyReducer,
//   deleteEmailSendVerifyReducer,
//   otpSendVerifyReducer,
// } from "./accountPro/organisation/reducer";

// import {
//   updateProfileReducer,
//   getProfileReducer,
//   updatePasswordReducer,
// } from "../redux/accountPro/profile/reducer";
// import {
//   getSubAccountantReducer,
//   addSubAccountantReducer,
//   updateSubAccountantReducer,
//   deleteSubClientReducer,
// } from "./subAccountant/reducer";
// import {
//   otpTimeStartReducer,
//   signupVerifyBackBtnTimeStopReducer,
//   mbleSccrnSideBrClseReducer,
// } from "./ComponentDataSend/authComponentDataSend/reducers";

// // new code
// import {
//   signupAccountReducer,
//   getSkillReducer,
// } from "./auth/accountant/reducers";
// import {
//   accountDetailReducer,
//   accountSettingReducer,
//   getAccountantProfileReducer,
// } from "./accountant/profile/acountDetail/reducers";
// import { getSearchAccountantReducer } from "./accountant/searchAccountant/reducers";

import {
  getAnnouncementReducer,
  addAnnouncementReducer,
  updateAnnouncementReducer,
  deleteAnnouncementReducer,
  searchClientReducer,
  searchAccountantAssignReducer
} from "./superAdmin/Announcement/reducer";

import {
  searchAccountantReducer,
  assignAccountantReducer,
  getAssignedAccountantReducer,
  getAllAccountantsReducer, getAssignedClientReducer,
  updateProfileReducerAcc,
  importAnnouncementReducer, exportAccountantReducer, getImportedAccountantReducer
} from "./superAdmin/Accountant/reducer";

import { getClientReducer, getUnassignedClientReducer, updateProfileReducer } from "./superAdmin/Clients/reducer";
import { getLanguagesReducer, addLanguageReducer, updateLanguagesReducer, deleteLanguagesReducer } from "./superAdmin/Languages/reducer";

import { addChargeReducer, getChargeReducer, getSlabReducer, deleteChargeReducer, updateChargeReducer } from './superAdmin/Charges/reducers';
import { getPaymentsReducer } from "./superAdmin/Payments/reducers";
export default (combineReducers({
  Auth,
  Layout,
  getAnnouncementReducer,
  addAnnouncementReducer,
  updateAnnouncementReducer,
  deleteAnnouncementReducer,
  getClientReducer, getUnassignedClientReducer,
  searchAccountantReducer,
  assignAccountantReducer,
  searchClientReducer,
  getAssignedAccountantReducer,
  getAllAccountantsReducer, getAssignedClientReducer,
  updateProfileReducer,
  updateProfileReducerAcc,
  searchAccountantAssignReducer,
  importAnnouncementReducer, exportAccountantReducer, getImportedAccountantReducer,
  getLanguagesReducer,
  addLanguageReducer,
  updateLanguagesReducer,
  deleteLanguagesReducer,
  addChargeReducer, getChargeReducer, getSlabReducer, deleteChargeReducer, updateChargeReducer,
  getPaymentsReducer,
}): any);
