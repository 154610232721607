import { announcements } from "./constant";
const initial_state = {
  data: [],
  message: "",
  loading: false,
};

export const getAnnouncementReducer = (state = initial_state, action) => {
  switch (action.type) {
    case announcements.GET_ANNOUNCEMENT_LOADING:
      return {
        data: initial_state.data,
        loading: true,
      };
    case announcements.GET_ANNOUNCEMENT_SUCCESS:
      return {
        data: action?.payload,
        loading: false,
      };
    case announcements.GET_ANNOUNCEMENT_ERROR:
      return {
        data: [],
        loading: false,
        message: action?.payload,
      };
    default:
      return { ...state };
  }
};

export const searchAccountantAssignReducer = (state = initial_state, action) => {
  switch (action.type) {
    case announcements.SEARCH_ACCOUNTANT_LOADINGS:
      return {
        data: initial_state.data,
        loading: true,
      };
    case announcements.SEARCH_ACCOUNTANT_SUCCESSS:
      return {
        data: action?.payload,
        loading: false,
      };
    case announcements.SEARCH_ACCOUNTANT_ERRORS:
      return {
        data: [],
        loading: false,
        message: action?.payload,
      };
    case announcements.SEARCH_ACCOUNTANT_RESETS:
      return initial_state;
    default:
      return { ...state };
  }
};

export const searchClientReducer = (state = initial_state, action) => {
  switch (action.type) {
    case announcements.SEARCH_CLIENT_LOADING:
      return {
        data: initial_state.data,
        loading: true,
      };
    case announcements.SEARCH_CLIENT_SUCCESS:
      return {
        data: action?.payload,
        loading: false,
      };
    case announcements.SEARCH_CLIENT_ERROR:
      return {
        data: [],
        loading: false,
        message: action?.payload,
      };
    default:
      return { ...state };
  }
};

export const addAnnouncementReducer = (state = initial_state, action) => {
  switch (action.type) {
    case announcements.POST_ANNOUNCEMENT_LOADING:
      return {
        data: initial_state.data,
        loading: true,
      };
    case announcements.POST_ANNOUNCEMENT_SUCCESS:
      return {
        data: action?.payload,
        loading: false,
      };
    case announcements.POST_ANNOUNCEMENT_RESET:
      return {
        data: initial_state.data,
        loading: false,
      };
    case announcements.POST_ANNOUNCEMENT_ERROR:
      return {
        data: action?.payload,
        loading: false,
      };
    default:
      return { ...state };
  }
};

export const updateAnnouncementReducer = (state = initial_state, action) => {
  switch (action.type) {
    case announcements.UPDATE_ANNOUNCEMENT_LOADING:
      return {
        data: initial_state.data,
        loading: true,
      };
    case announcements.UPDATE_ANNOUNCEMENT_SUCCESS:
      return {
        data: action?.payload,
        loading: false,
      };
    case announcements.UPDATE_ANNOUNCEMENT_RESET:
      return {
        data: state,
        loading: false,
      };
    case announcements.UPDATE_ANNOUNCEMENT_ERROR:
      return {
        data: action?.payload,
        loading: false,
      };
    default:
      return { ...state };
  }
};

export const deleteAnnouncementReducer = (state = initial_state, action) => {
  switch (action.type) {
    case announcements.DELETE_ANNOUNCEMENT_LOADING:
      return {
        ...state,
        loading: true,
      };
    case announcements.DELETE_ANNOUNCEMENT_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case announcements.DELETE_ANNOUNCEMENT_ERROR:
      return {
        ...state,
        loading: false,
      };
    case announcements.DELETE_ANNOUNCEMENT_RESET:
      return initial_state;
    default:
      return state;
  }
};
