import * as URL from "../../../helpers/api/apiEndPoint";
import { APICore } from "../../../helpers/api/apiCore";
const api = new APICore();

export function getassignedClientsApi(data): any {
  const page = data?.payload?.skip ? data.payload.skip : 1;
  const searchQuery = data?.payload?.search || ''; // Get the search query from data.payload or use an empty string if not provided
  const url = `${URL.GET_ALL_ASSIGNED_CLIENTS}?skip=${page}&limit=10&search=${searchQuery}&nameSort=${data?.payload?.nameSort || ''}`;

  return api.get(url);
}
export function getUnassignedClientsApi(data): any {
  const page = data?.payload?.skip ? data.payload.skip : 1;
  const searchQuery = data?.payload?.search || '';
  const url = `${URL.GET_UNASSIGNED_CLIENTS}?skip=${page}&limit=10&search=${searchQuery}&nameSort=${data?.payload?.nameSort || ''}`;

  return api.get(url);
}
export function uploadProfileApiCli(payload): any {
  // console.log(payload, 'updateProfile')
  return api.update(URL.UPDATE_PROFILE, payload?.payload);
}
